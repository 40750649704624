import axios from 'axios';

const API_KEY = '44b4639cea3235'; // Replace with your ipinfo API key

export const getGeolocation = async () => {
    try {
        const response = await axios.get(`https://ipinfo.io?token=${API_KEY}`);
        return response.data;
    } catch (error) {
        console.error('Error fetching geolocation data:', error);
        throw error;
    }
};
