<template>
  <div id="login" style="min-height: calc(100vh - 20px);" tag="section">
    <b-row class="justify-content-center no-gutters">
      <b-col lg="12" class="d-flex align-items-center">
        <b-card no-body class="w-100">
          <b-row class="no-gutters" style="min-height: 100vh;">
            <b-col
                v-if="innerWidth > mobileWidth"
                lg="6"
                class="d-md-flex align-items-center justify-content-center"
            >
              <b-carousel
                  id="carousel-no-animation"
                  style="text-shadow: 0px 0px 2px #000;"
                  no-animation
                  indicators
                  class="w-100"
              >
                <b-carousel-slide v-for="(items, index) in Banner" :key="index"
                                  :img-src="items.url"
                ></b-carousel-slide>
              </b-carousel>
            </b-col>
            <b-col
                lg="6"
                class="d-md-flex align-items-center justify-content-center"
            >
              <div class="d-flex align-items-center w-100">
                <div class="p-5 w-100">
                  <b-row class="justify-content-center">
                    <b-col cols="12" lg="9" xl="6">
                      <img v-show="BrandSetting?.image?.logo"
                           :src="BrandSetting?.image?.logo"
                           width="100px"
                           alt="logo"
                      />
                      <img v-show="BrandSetting?.image?.text_logo"
                           :src="BrandSetting?.image?.text_logo"
                           width="250px"
                           alt="text_logo"
                      />
                      <b-row class="justify-content-center mt-4">
                        <b-col cols="12" lg="9" xl="6">
                          <h2 class="font-weight-bold">
                            {{ $t('login.signIn') }}
                          </h2>
                        </b-col>
                        <b-col cols="12" lg="3" xl="6" align="end">
                          <language/>
                        </b-col>
                      </b-row>
                      <b-form class="mt-2">
                        <input-component
                            type="text"
                            is-login-form
                            :placeholder="$t('common.enter_email_or_phone')"
                            v-model="$v.form.username.$model"
                            :state="form.username === ''  || checkValidateFormat?.invalidState"
                            :require-label="form.username !== '' && !checkValidateFormat?.invalidState ? checkValidateFormat?.invalidInput : ''"
                            @keypress="checkIsNotThai"
                            @keyup.enter="nextPlease"
                        />
                        <div>
                          <input-component
                              type="password"
                              icon="password"
                              :placeholder="$t('common.enter_password')"
                              v-model="$v.form.password.$model"
                              @keyup.enter="enterLogin"
                          />
                        </div>
                        <div class="d-flex align-items-center">
                          <b-form-checkbox
                              v-model="rememberMe"
                          >
                            {{ $t('login.rememberMe') }}
                          </b-form-checkbox>
                          <div class="ml-auto">
                            <a href="javascript:void(0)" @click="()=>{$router.push('Forgot-Password')}" class="link">
                              {{ $t('login.forgotPassword') }}
                            </a>
                          </div>
                        </div>
                        <b-button
                            variant="gradient"
                            :disabled="$v.form.$invalid || !checkValidateFormat?.invalidState"
                            pill
                            block
                            class="mt-4 mb-1"
                            @click="login">
                          {{ $t('login.signIn') }}
                          <b-spinner small v-show="loginSuccess"/>
                        </b-button>
                        <label style="color: #ff3b3b;">{{ errLabel }}</label>
                      </b-form>
                      <div class="separator mt-5" v-if="BrandSettingMedia?.facebook_login?.allow_login ||
                      BrandSettingMedia?.line_login?.allow_login ||
                      BrandSettingMedia?.google_login?.allow_login && is_browser !== 'line'">
                        {{ $t('login.orSignInWith') }}
                      </div>
                      <div
                          class="d-flex align-items-center justify-content-center mt-3"
                      >
                        <img v-if="BrandSettingMedia?.facebook_login?.allow_login"
                             @click="loginWithMedia('facebook')"
                             src="@/assets/images/social/Facebook.png"
                             class="img-fluid rounded-circle logo-login"
                             width="30"
                             alt="facebook"
                        />
                        <img v-if="BrandSettingMedia?.line_login?.allow_login"
                             @click="loginWithMedia('line')"
                             src="@/assets/images/social/line.png"
                             class="img-fluid rounded-circle logo-login"
                             width="30"
                             alt="line"
                        />
                        <img v-if="BrandSettingMedia?.google_login?.allow_login && is_browser !== 'line'"
                             @click="loginWithMedia('google')"
                             src="@/assets/images/social/google.jpeg"
                             class="img-fluid rounded-circle logo-login"
                             width="30"
                             alt="google"
                        />
                      </div>
                      <div class="mt-5">
                        <b-button
                            variant="light"
                            pill
                            block
                            class="mb-4"
                            @click="()=>{$router.push('Register')}"
                        >
                          <span>{{ $t('login.dontHaveAccount') }}</span>
                          <span class="ml-2 text-primary"> {{ $t('login.SignUp') }}</span>
                        </b-button>
                      </div>
                      <div>
                        <footer-blank/>
                      </div>
                    </b-col>
                  </b-row>
                </div>
              </div>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
    <!--  is verify phone   -->
    <div>
      <b-modal id="modal-is-verify-phone" hide-footer centered no-close-on-esc no-close-on-backdrop>
        <template #modal-header="{ close }">
          <div></div>
          <i class="fas fa-times hover-icon action-button" @click="closePopUpVerify"></i>
        </template>
        <div class="d-block text-center p-2">
          <div class="mb-3">
            <img src="@/assets/images/icon/info.svg" alt="info" width="80"/>
          </div>
          <h3>{{ $t('login.transaction_failed') }}</h3>
          <div class="mt-3 mb-3">
            "{{ $t('login.please_login') }}"
            <div>
              {{ $t('login.to_be_able_with_phone') }}
              {{ $t('login.more_then_one_user') }}
            </div>
          </div>
        </div>
        <div class="text-center mb-3">
          <b-button class="mt-3 w-50" variant="primary" @click="closePopUpVerify">{{ $t('button.confirm') }}</b-button>
        </div>
      </b-modal>
    </div>
  </div>
</template>

<script>
import {validationMixin} from 'vuelidate'
import language from '@/components/common/Language'
import {required, minLength, email} from 'vuelidate/lib/validators'
import authApi from '@/repository/authApi'
import CryptoJS from "crypto-js";
import InputComponent from "@/components/common/inputComponent";
import Footer from "@/layouts/full-layout/footer/Footer.vue";
import FooterBlank from "@/layouts/full-layout/footer/FooterBlankLayout.vue";
import functionsCommon from "@/common/functions";
import functionCommon from "@/common/functions";
import {mapState} from "vuex";
import { getGeolocation } from '@/repository/geoService';

export default {
  name: 'Login',
  mixins: [validationMixin],
  components: {FooterBlank, Footer, InputComponent, language},
  data: () => ({
    form: {
      username: '',
      password: '',
      strategy: 'local',
    },
    errLabel: '',
    rememberMe: false,
    loginSuccess: false,
    package_id: null,
    custom_package: null,
    is_browser: null,
    is_show_pass: false,
    ip: null,
    country: null,
    region: null,
    city: null,
    languageWithCountry: [
      {
        country: "TH",
        lang: "th"
      },
      {
        country: "CN", // China
        lang: "cn"
      },
      {
        country: "TW", // Taiwan
        lang: "cn"
      },
      {
        country: "VN", // Vietnam
        lang: "vn"
      },
      {
        country: "MY", // Malaysia 
        lang: "my"
      },
      {
        country: "ID", // Indonesia
        lang: "id"
      }
    ]
  }),
  validations: {
    form: {
      username: {
        required,
      },
      password: {
        required,
        minLength: minLength(1),
      },
    },
  },
  beforeCreate() {
    // Add google-site-verification to meta
    if (!document.querySelector('meta[name="google-site-verification"]')) {
      const meta = document.createElement('meta')
      meta.name = 'google-site-verification'
      meta.content = 'uQhvc3Vw4VzvlAgwU-V9_ghD4hVXOXfX_1oPLRjATsM'
      document.getElementsByTagName('head')[0].appendChild(meta)
    }
  },
  mounted() {
    this.getCookie();
    const tracking_channel = this.$route.query.tracking_channel || this.getCookie('TRACKING_CHANNEL');
    if (tracking_channel) {
      this.form.tracking_channel = tracking_channel
      this.setCookieChannel('TRACKING_CHANNEL', this.form.tracking_channel, 1)
    }
  },
  async created() {
    const channel = this.$route.query.channel ? this.$route.query.channel : null
    localStorage.setItem("channel", channel)
    this.mappingLang();
  },
  computed: {
    Banner() {
      return this.$store.getters['profile/getBanner']
    },
    BrandSetting() {
      return this.$store.getters['profile/getBandSetting']
    },
    BrandSettingMedia() {
      return this.$store.getters['profile/getBandSettingMedia']
    },
    checkValidateFormat() {
      const checkEmailOrPhone = functionsCommon.checkFormatEmailOrPhone(this.form.username)
      if (checkEmailOrPhone === 'email') {
        return {
          invalidState: functionCommon.emailState(this.form.username).state,
          invalidInput: this.$t(functionCommon.emailState(this.form.username).invalid),
        }
      } else if (checkEmailOrPhone === 'phone') {
        return {
          invalidState: functionCommon.telState(this.form.username),
          invalidInput: this.$t(functionCommon.telInvalid(this.form.username)),
        }
      }
    },
    ...mapState({
      innerWidth: state => state.theme.width_inner,
      mobileWidth: state => state.theme.mobile_width
    }),
  },
  methods: {
    async mappingLang() {
      try {
        const geoData = await getGeolocation();
        this.ip = geoData.ip;
        this.region = geoData.region;
        this.city = geoData.city;
        this.country = geoData.country;
        // map language with country
        const lang = this.languageWithCountry.find(item => item.country === this.country);
        if (lang) {
          this.$i18n.locale = lang.lang;
          sessionStorage.setItem('lang', lang.lang)
        } else {
          this.$i18n.locale = 'en';
          sessionStorage.setItem('lang', 'en')
        }
      } catch (error) {
        console.error('Error getting geolocation data:', error);
      }
    },
    validateState(username) {
      const {$dirty, $error} = this.$v.form[username]
      return $dirty ? !$error : null
    },
    login() {
      this.loginSuccess = true
      const username = this.form.username.trim()
      const data = {
        email: username,
        password: this.form.password,
        strategy: 'local',
      }
      authApi.login(data).then((response) => {
        if (response.codeSYS === '001') {
          if (this.rememberMe) {
            this.setCookie(
                this.form.username,
                this.form.password,
                this.rememberMe,
                30,
            )
          } else {
            this.clearCookie()
          }
          this.loginSuccess = false
          if (!_.isNil(this.package_id) || !_.isEmpty(this.package_id)) {
            this.clearCookie('P_ID')
            this.$router.push(`/Subscriptions/Package?package_id=${this.package_id}&go_to=checkout`)
          } else if (!_.isNil(this.custom_package) || !_.isEmpty(this.custom_package)) {
            this.clearCookie('CUSTOM_PRICE')
            this.$router.push(`/Subscriptions/Package?price=${this.custom_package}`)
          } else {
            this.$router.push('/').catch(() => {
            })
          }
        } else if (response?.is_verify_phone) {
          this.$bvModal.show('modal-is-verify-phone')
        } else {
          this.loginSuccess = false
          this.errLabel = response?.message
        }
        return response
      }).then(data => {
        if (!!data) {
          authApi.getCustomerProfile().then(resp => {
            if (resp) {
              localStorage.setItem("profile", JSON.stringify(resp));
              this.$store.dispatch('profile/setUserInfo', resp)
              const profile = this.$store.getters["profile/getUserInfo"]
              this.$i18n.locale = profile?.locale ? profile?.locale : 'th'
              this.$store.dispatch('profile/setName', profile?.first_name + " " + profile?.last_name)
              this.$store.dispatch('profile/setCredit', profile.sms_unit)
              this.$store.dispatch('profile/setURLTracking', profile.url_tracking)

            }
          }).catch(err => {
            throw err
          })
        }
      }).catch((err) => {
        this.loginSuccess = false
        if (err?.response?.data?.errors?.code === '402') {
          const data = err?.response?.data?.data
          if (this.BrandSettingMedia?.verify_factors?.is_verify_otp) {
            this.$router.push(`/Register?customer_id=${data?.customer_id}&email=${data?.email}&first_name=${data?.first_name}&last_name=${data?.last_name}&phone=${data?.phone}`).catch(() => {
            })
          } else {
            localStorage.setItem('signup_email', this.form.email)
            this.$router.push(`/Verify-Email`).catch(() => {
            })
          }
        } else if (err?.response?.data?.code === 500) {
          window.location.reload();
        } else {
          this.errLabel = err?.response?.data?.message
        }
      }).finally(() => {
        this.loginSuccess = false
      })
    },
    clearCookie(value) {
      if (value) {
        document.cookie = value + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;'
      } else {
        this.setCookie('', '', '', -1)
      }
    },
    setCookie(user, psw, remember, expDay) {
      let cipherUsername = CryptoJS.AES.encrypt(
          user + '',
          'secret_key_pws',
      ).toString()
      let cipherPsw = CryptoJS.AES.encrypt(
          psw + '',
          'secret_key_pws',
      ).toString()
      let ExDate = new Date() // get time
      ExDate.setTime(ExDate.getTime() + 24 * 60 * 60 * 1000 * expDay)
      window.document.cookie =
          'is_remember' +
          '==' +
          remember +
          ';path=/;expires=' +
          ExDate.toGMTString()
      window.document.cookie =
          'UN' + '==' + cipherUsername + ';path=/;expires=' + ExDate.toGMTString()
      window.document.cookie =
          'PW' + '==' + cipherPsw + ';path=/;expires=' + ExDate.toGMTString()
    },
    setCookieChannel(name, value, days) {
      let expires = "";
      if (days) {
        let date = new Date();
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
        expires = "; expires=" + date.toUTCString();
      }
      document.cookie = name + "==" + (value || "") + expires + "; path=/";
    },
    getCookie() {
      if (document.cookie.length > 0) {
        let arr = document.cookie.split(';')
        for (let i = 0; i < arr.length; i++) {
          let arr2 = arr[i].split('==')
          if (arr2[0].trim() === 'is_remember' && arr2[1].trim() === 'true') {
            this.rememberMe = true
            arr.forEach((val) => {
              let cookie = val.split('==')
              if (cookie[0].trim() === 'UN') {
                let bytes = CryptoJS.AES.decrypt(
                    cookie[1].toString(),
                    'secret_key_pws',
                )
                this.form.username = bytes.toString(CryptoJS.enc.Utf8)
              } else if (cookie[0].trim() === 'PW' || cookie[0].trim() === 'password') {
                let bytes = CryptoJS.AES.decrypt(
                    cookie[1].toString(),
                    'secret_key_pws',
                )
                this.form.password = bytes.toString(CryptoJS.enc.Utf8)
              }
            })
          } else if (arr2[0].trim() === 'P_ID') {
            this.package_id = arr2[1].trim()
          } else if (arr2[0].trim() === 'CUSTOM_PRICE') {
            this.custom_package = arr2[1].trim()
          } else if (arr2[0].trim() === 'BROWSER') {
            this.is_browser = arr2[1].trim()
          }
        }
      }
    },
    loginWithMedia(channel) {
      const groupId = sessionStorage.getItem('group_id')
      const brandId = sessionStorage.getItem('brand_id')
      const path = window.location.origin
      const mapQuery = {
        group_id: groupId,
        brand_id: brandId
      }
      const queryString = new URLSearchParams(mapQuery)
      const str = `redirect=${path}/Loading&${queryString.toString()}`
      window.location.href = `${process.env.VUE_APP_ROOT_API}/oauth/${channel}?${str}`
    },
    nextPlease: function (event) {
      if (this.form.username !== '') {
        document.getElementById('txt-pwd').focus();
      }
    },
    enterLogin() {
      if (this.$v.form.username.$error || this.$v.form.password.$error) return;
      this.login()
    },
    closePopUpVerify() {
      this.$bvModal.hide('modal-is-verify-phone')
      this.form.username = ''
      this.form.password = ''
    },
    checkIsNotThai(e) {
      return functionCommon.isOnlyEnglish(e)
    },
  },
  watch: {
    'form.username'(newVal) {
      if (newVal) {
        this.form.username = newVal.replace(" ", "")
        this.errLabel = null
      }
    },
    'form.password' (newVal) {
      if (newVal) {
        this.errLabel = null
      }
    }
  },
}
</script>
<style>
.logo-login {
  cursor: pointer;
  margin-right: 10px;
  box-shadow: 4px 0px 4px 0px rgba(148, 149, 155, 0.55) !important;
}

.separator {
  display: flex;
  align-items: center;
  text-align: center;
}

.separator::before,
.separator::after {
  content: '';
  flex: 1;
  border-bottom: 1px solid #3e5569;
}

.separator:not(:empty)::before {
  margin-right: 0.25em;
}

.separator:not(:empty)::after {
  margin-left: 0.25em;
}

.form-control.is-valid, .was-validated .form-control:valid, .form-control:focus {
  border-color: #ced4da;
  padding-right: 12px;
  background-image: none;
}

.form-control::-webkit-input-placeholder {
  color: rgba(180, 180, 185, 0.98);
}

.form-control.is-valid:focus, .was-validated .form-control:valid:focus {
  border-color: #D5DFE9;
  box-shadow: transparent;
  padding-right: 12px;
}

.form-control.is-invalid, .was-validated .form-control:invalid {
  background-image: none;
  padding-right: 12px;
}

.was-validated .form-control:valid, .form-control.is-valid {
  padding-right: 12px !important;
}

.was-validated .form-control:invalid, .form-control.is-invalid {
  padding-right: 12px !important;
  border: 1px solid var(--danger);
}

</style>
